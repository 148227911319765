
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';

  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ire-commrcail-streets-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      let disabledEditPercentages = ref<boolean>(false);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('streetsIreValuesUpdating'), [
          translate('ire'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_IRE_COMMON_STREET,
        route.params.id
      );

      const street = ref(data.data);
      let selectedRow = { id: 0, value: 0, decreasedPercentage: 0 };
      const rowValue = ref(0);

      const editRow = (row) => {
        selectedRow = row;
        rowValue.value = selectedRow.value;
      };

      const sizeQualityConfig = ref();

      const groubBySizeConfig = () => {
        sizeQualityConfig.value = street.value.sizeConfig.reduce((acc, obj) => {
          const key = ['propertyTypeId', 'floorTypeId', 'directionId']
            .map((property) => obj[property])
            .join('|');
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        const arrKeys = Object.keys(sizeQualityConfig.value);
        arrKeys.forEach((key) => {
          return sizeQualityConfig.value[key].sort((a, b) => a.size - b.size);
        });
      };

      groubBySizeConfig();
      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const streetSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
      });
      const onSubmitUpdate = async (values) => {
        delete values.AreasName;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          sort: Number(values.sort),
        };
        await store.dispatch(Actions.UPDATE_IRE_COMMON_STREET, {
          id: street.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_STREET'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.go(0);
          });
        }
      };

      const onSizeConfigSubmit = async () => {
        await store.dispatch(Actions.UPDATE_IRE_COMMON_STREET_CONFIG, {
          id: selectedRow.id,
          data: {
            value: +rowValue.value,
            decreasedPercentage: selectedRow.decreasedPercentage,
          },
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          const { data } = await store.dispatch(
            Actions.GET_IRE_COMMON_STREET,
            route.params.id
          );
          street.value = data.data;
          groubBySizeConfig();
        }
      };

      return {
        disabledEditPercentages,
        sizeQualityConfig,
        selectedRow,
        onSubmitUpdate,
        submitButton,
        translate,
        streetSchema,
        goBack,
        onSizeConfigSubmit,
        street,
        can,
        editRow,
        rowValue,
      };
    },
  });
